import { Divider } from "../divider/Divider";
import { Section } from "../Section/Section";

import photoFive from "../../resources/PhotoShowcase/5.PNG";
import photoSix from "../../resources/PhotoShowcase/6.PNG";
import photoSeven from "../../resources/PhotoShowcase/7.PNG";
import photoEight from "../../resources/PhotoShowcase/8.PNG";
import photoNine from "../../resources/PhotoShowcase/9.PNG";
import photoTen from "../../resources/PhotoShowcase/10.PNG";
import photoEleven from "../../resources/PhotoShowcase/11.PNG";
import photoTwelve from "../../resources/PhotoShowcase/12.PNG";
import photoThirteen from "../../resources/PhotoShowcase/13.PNG";
import photoFourteen from "../../resources/PhotoShowcase/14.PNG";
import photoFiveteen from "../../resources/PhotoShowcase/15.PNG";
import photoSixteen from "../../resources/PhotoShowcase/16.PNG";
import React, { useState, useEffect } from "react";

export const PhotoShowcase = () => {
  const [batchIndex, setBatchIndex] = useState(0);
  const images = [
    [photoFive, photoSix, photoSeven, photoEight],
    [photoNine, photoTen, photoEleven, photoTwelve],
    [photoSixteen, photoThirteen, photoFourteen, photoFiveteen],
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setBatchIndex((prevBatchIndex) => (prevBatchIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);
  return (
    <Section full id="photo-showcase">
      <div className="photo-showcase__wrapper">
        <Divider />
        <img
          src={images[batchIndex][0]}
          className="photo__main"
          alt="car image"
        />
        <div className="photo-showcase__triplet">
          <img src={images[batchIndex][1]} alt="car image" />
          <img src={images[batchIndex][2]} alt="car image" />
          <img src={images[batchIndex][3]} alt="car image" />
        </div>
      </div>
    </Section>
  );
};
